import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { SHOWROOM_NAME, mediaQueryPreset } from '~/containers/shared/constants';
import { BLOG_INDEX_NAME, BLOG_INDEX_PATH } from '~/containers/Blog/constants';
import atoms from '~/containers/shared/states/atoms';
import {
  PARTNERS_PATH,
  SHOPPING_APP_CMS_PAGE_PATH,
  SHOWROOM_CMS_PAGE_PATH
} from '~/utils/pagePaths';
import appsFlyerIds from '~/utils/appsflyerConfig';

export default function useNavBaseOptions() {
  const { t } = useTranslation();
  const isClient = useRecoilValue(atoms.isClient);
  const isDesktop = useMediaQuery(mediaQueryPreset.desktop);

  const currentPath = useMemo(
    () => (isClient ? globalThis?.location?.pathname : ''),
    [isClient]
  );

  const rawData = useMemo(
    () => [
      {
        hasNew: false,
        stickyLabel: 'Shop',
        burgerLabel: 'Shop by Categories',
        path: '/',
        title: 'Go to Shop',
        matchBy: false,
        showChildren: true
      },
      {
        hasNew: true,
        stickyLabel: BLOG_INDEX_NAME,
        burgerLabel: 'Home Styling Ideas',
        path: BLOG_INDEX_PATH,
        title: 'Go to Ideas by HipVan',
        matchBy: [BLOG_INDEX_PATH],
        showChildren: false
      },
      {
        hasNew: false,
        stickyLabel: 'Visit',
        burgerLabel: 'Visit our Atelier Pop-up',
        path: SHOWROOM_CMS_PAGE_PATH,
        title: `Go to  ${SHOWROOM_NAME}`,
        matchBy: [SHOWROOM_CMS_PAGE_PATH, 'warehouse-pop'],
        showChildren: false
      },
      {
        hasNew: false,
        stickyLabel: 'App',
        burgerLabel: 'Download App',
        path: isDesktop
          ? SHOPPING_APP_CMS_PAGE_PATH
          : t('appsflyer_url', { cid: appsFlyerIds.NAV_HEADER }),
        title: 'Download HipVan App',
        matchBy: [SHOPPING_APP_CMS_PAGE_PATH],
        showChildren: false
      },
      {
        hasNew: false,
        stickyLabel: 'Partners',
        burgerLabel: 'Partners',
        path: PARTNERS_PATH,
        title: 'Go to HipVan Partners',
        matchBy: [PARTNERS_PATH],
        showChildren: false
      }
    ],
    [isDesktop, t]
  );

  const allMatchBys = useMemo(
    () =>
      rawData.reduce(
        (acc, { matchBy = [] } = {}) =>
          matchBy.length ? acc.concat(matchBy) : acc,
        []
      ),
    [rawData]
  );

  const paths = useMemo(
    () =>
      rawData.map((opt) => {
        const selfMatching = Array.isArray(opt?.matchBy) && opt?.matchBy.length;
        const matchCriteria = selfMatching ? opt?.matchBy : allMatchBys;

        const selfMatches = matchCriteria.reduce(
          (acc, path) => (currentPath.indexOf(path) > -1 ? acc + 1 : acc),
          0
        );

        return {
          ...opt,
          isActive: selfMatching ? selfMatches > 0 : selfMatches === 0
        };
      }),
    [allMatchBys, currentPath, rawData]
  );

  return paths;
}
