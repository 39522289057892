import React, { useMemo, useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import atoms from '~/containers/Header/states/atoms';
import useNavBaseOptions from '~/hooks/Header/useNavBaseOptions';
import { humanListCount } from '~/utils/helper';
import { hitLocations } from '~/utils/analytics/tracking';
import { trackBaseLinkClick } from '~/containers/Header/analytics';
import './List.scss';

function WrapTag({
  index,
  shortLabel = '',
  displaylabel = '',
  href = '#',
  title = undefined,
  isActive = false,
  isToggle = false,
  children = null
}) {
  const COMMON_CLASSNAMES = `c-nvPageList__link u-block is-${shortLabel.toLowerCase()}`;
  const setBurgerOpen = useSetRecoilState(atoms.burgerOpen);

  const commonData = useMemo(
    () => ({
      label: displaylabel,
      listPosition: humanListCount(index),
      location: hitLocations?.burger_nav_accordion
    }),
    [displaylabel, index]
  );

  const handleClickToggle = useCallback(
    () => trackBaseLinkClick(commonData),
    [commonData]
  );

  const handleClickActive = useCallback(() => {
    setBurgerOpen(false);
    trackBaseLinkClick({ url: `self: ${href}`, ...commonData });
  }, [commonData, href, setBurgerOpen]);

  const handleClickLink = useCallback(
    () => trackBaseLinkClick({ url: href, ...commonData }),
    [commonData, href]
  );

  if (isToggle)
    return (
      <div
        className={`${COMMON_CLASSNAMES} is-toggle u-t-nooutline`}
        role="button"
        tabIndex={-1}
        onClick={handleClickToggle}
      >
        {children}
      </div>
    );

  if (isActive)
    return (
      <div
        className={`${COMMON_CLASSNAMES} u-t-primary u-p-pointer u-t-nooutline is-active`}
        role="button"
        tabIndex={-1}
        onClick={handleClickActive}
      >
        {children}
      </div>
    );

  return (
    <a
      className={`${COMMON_CLASSNAMES} u-t-primary u-t-nolined is-still`}
      href={href}
      title={title}
      onClick={handleClickLink}
    >
      {children}
    </a>
  );
}

function BaseRow({ data = {}, index, children = null }) {
  const {
    path,
    title,
    stickyLabel,
    burgerLabel,
    hasNew = false,
    isActive = false,
    showChildren = false
  } = data;

  const setBurgerOpen = useSetRecoilState(atoms.burgerOpen);

  const hasChildren = useMemo(
    () => showChildren && !isEmpty(children),
    [children, showChildren]
  );

  const handleClick = useCallback(
    () => (hasChildren ? setBurgerOpen(false) : false),
    [hasChildren, setBurgerOpen]
  );

  if (!burgerLabel) return null;

  return (
    <WrapTag
      index={index}
      shortLabel={stickyLabel}
      displaylabel={burgerLabel}
      href={hasChildren ? undefined : path}
      title={title}
      isActive={isActive}
      isToggle={showChildren}
    >
      <div className="c-nvPageList__inner">
        <div className="c-nvPageList__flex">
          <div className="c-nvPageList__text">
            <span className="c-nvPageList__label u-t-bold u-t-ellipsis">
              {burgerLabel}
            </span>
            {hasNew && (
              <span className="c-nvPageList__new u-inline-block u-t-white u-b-secondary">
                NEW
              </span>
            )}
          </div>
          <div
            className={classNames('c-nvPageList__icon ic-bef ic-xbld', {
              'ic-site-cross u-p-pointer': !!hasChildren,
              'ic-site-arrow ic-dir-right': !hasChildren
            })}
            role="button"
            tabIndex={-1}
            onClick={handleClick}
          />
        </div>
        {hasChildren && <div className="c-nvPageList__body">{children}</div>}
      </div>
    </WrapTag>
  );
}

export default function NavBaseOptionsList({ children = null }) {
  const paths = useNavBaseOptions();

  return (
    <div className="c-nvPageList">
      {paths.map((data, idx) => (
        <BaseRow key={data?.burgerLabel} index={idx} data={data}>
          {!!data?.showChildren && children}
        </BaseRow>
      ))}
    </div>
  );
}
