import keyMirror from 'keymirror';

const atomKeys = keyMirror({
  READY_FOR_CALLOUTS: null,
  SHOW_STICKY_PROMO: null,
  TRIED_RECOVERY_CALLOUT: null,
  ACCOUNT_MODAL_SHOW: null,
  PROFILE_MODAL_SHOW: null,
  SHOW_REGISTER_MODAL: null,
  SHOW_RESET_PASSWORD_MODAL: null,
  AUTH_CONFIG: null,
  AUTH_MODE: null,
  AUTH_CONTENT: null,
  AUTH_ERROR_MESSAGE: null,
  AUTH_LOCATION: null,
  AUTH_EXTRA_INFO: null,
  AUTH_CONFIG_UPDATE: null
});

const REQUIRED_ERROR_MSG = 'This field is required.';

const SESSION_KEY = 'customer_session_id';
const PREV_SESSION_KEY = 'hvm_prev';

const CALLOUT_IMPRESSION_DELAY = 2000;
const CALLOUT_COUNTS_CACHE = 'hvm_counts';
const RECOVERY_CHECK_DELAY = 20000;

const REQUEST_INFO_LATER_KEY = 'hvm_later';
const REQUEST_INFO_LATER_DURA = { days: 7 };

const LEADSUB_MAIL_LIST_ID = '1183544';
const DEFAULT_LEADSUB_PAUSE_DURA = { days: 7 };
const LEAD_SUB_CONFIG_KEY = 'LDSB_CONFIG';
const LEADSUB_PAUSE_KEY = 'hvm_ldsb_wait';
const LEADSUB_COUNT_KEY = 'hvm_ldsb_imp';
const LEADSUB_SUCCESS_KEY = 'hvm_ldsb_end';
const LEADSUB_VARIANT_KEY = 'hvm_ldsb_var';

const STICKY_PROMO_CONFIG_KEY = 'STPR_CONFIG';
const STICKY_PROMO_RATIO_W_MOBL = 5;
const STICKY_PROMO_RATIO_W_DESK = 20;
const STICKY_PROMO_DEFAULT_INTERVAL = 2500;
const STICKY_PROMO_PAUSE_KEY = 'hvm_promo_wait';
const STICKY_PROMO_COUNT_KEY = 'hvm_promo_imp';
const STICKY_PROMO_VARIANT_KEY = 'hvm_promo_var';

const DEFAULT_APP_NUDGE_PAUSE_DURA = { days: 1 };
const APP_NUDGE_CONFIG_KEY = 'APPN_CONFIG';
const APP_NUDGE_PAUSE_KEY = 'hvm_nudge_wait';
const APP_NUDGE_COUNT_KEY = 'hvm_nudge_imp';
const APP_NUDGE_VARIANT_KEY = 'hvm_nudge_var';

const EXIT_INTENT_CONFIG_KEY = 'XINT_CONFIG';
const EXIT_INTENT_PAUSE_KEY = 'hvm_eint_wait';
const EXIT_INTENT_COUNT_KEY = 'hvm_eint_imp';

const AUTH_MODE = {
  SIGNUP: 'signup',
  LOGIN: 'login'
};

const AUTH_CONTENT = {
  GENERAL: 'general',
  REVIEWS: 'reviews',
  REWARDS: 'rewards',
  REFERRAL: 'referral',
  WISHLIST: 'wishlist',
  NOTIFY: 'notify',
  LIKES: 'likes',
  COMMENT_LIKES: 'comment_likes',
  COMMENTS: 'comments',
  FOLLOWS: 'follows',
  POST_JOIN: 'post_join',
  COMMUNITY: 'community',
  USER_SUGGEST: 'user_suggest',
  SAVES: 'saves'
};
const LOGIN_API = '/login';
const SIGNUP_API = '/registrations';
const FORGOT_PASSWORD_API = '/passwords/forgot_password';
const FACEBOOK_LOGIN_API = '/login/facebook';
const GOOGLE_LOGIN_API = '/login/google';
const GOOGLE_USER_INFO_URL = 'https://www.googleapis.com/oauth2/v3/userinfo';
const EMPTY_FIELD_ERROR = 'Oops, please fill in this field!';

const EMAIL = {
  id: 'my_account_email',
  fieldName: 'current_email',
  autoComplete: 'email'
};

const PASSWORD = {
  id: 'current_password',
  fieldName: 'current_password',
  autoComplete: 'current-password'
};

const SUBSCRIBE_NEWSLETTER = {
  id: 'newsletter_subscription',
  fieldName: 'newsletter_subscription'
};

const CUSTOMER_INFO_FIELD_KEYS = {
  EMAIL,
  PASSWORD,
  SUBSCRIBE_NEWSLETTER
};

export {
  atomKeys,
  REQUIRED_ERROR_MSG,
  SESSION_KEY,
  PREV_SESSION_KEY,
  CALLOUT_IMPRESSION_DELAY,
  CALLOUT_COUNTS_CACHE,
  RECOVERY_CHECK_DELAY,
  REQUEST_INFO_LATER_KEY,
  REQUEST_INFO_LATER_DURA,
  LEADSUB_MAIL_LIST_ID,
  DEFAULT_LEADSUB_PAUSE_DURA,
  LEAD_SUB_CONFIG_KEY,
  LEADSUB_PAUSE_KEY,
  LEADSUB_COUNT_KEY,
  LEADSUB_SUCCESS_KEY,
  LEADSUB_VARIANT_KEY,
  STICKY_PROMO_CONFIG_KEY,
  STICKY_PROMO_RATIO_W_MOBL,
  STICKY_PROMO_RATIO_W_DESK,
  STICKY_PROMO_DEFAULT_INTERVAL,
  STICKY_PROMO_PAUSE_KEY,
  STICKY_PROMO_COUNT_KEY,
  STICKY_PROMO_VARIANT_KEY,
  DEFAULT_APP_NUDGE_PAUSE_DURA,
  APP_NUDGE_CONFIG_KEY,
  APP_NUDGE_PAUSE_KEY,
  APP_NUDGE_COUNT_KEY,
  APP_NUDGE_VARIANT_KEY,
  EXIT_INTENT_CONFIG_KEY,
  EXIT_INTENT_PAUSE_KEY,
  EXIT_INTENT_COUNT_KEY,
  AUTH_MODE,
  AUTH_CONTENT,
  LOGIN_API,
  SIGNUP_API,
  FORGOT_PASSWORD_API,
  FACEBOOK_LOGIN_API,
  GOOGLE_LOGIN_API,
  GOOGLE_USER_INFO_URL,
  EMPTY_FIELD_ERROR,
  CUSTOMER_INFO_FIELD_KEYS
};
