import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion';
import Imgix, { buildURL } from 'react-imgix';
import classNames from 'classnames';
import { uniq } from 'lodash';
import 'lazysizes/plugins/attrchange/ls.attrchange';

import atoms from '~/containers/Header/states/atoms';
import {
  commonParams,
  squareAr,
  notFoundSrc,
  placeholdSrc,
  cleanUrlToImgix,
  lazyAttribs
} from '~/utils/imgix';
import { ga4Events } from '~/utils/analytics/gtm';
import { hitLocations } from '~/utils/analytics/tracking';
import { trackBrokenImage } from '~/containers/shared/analytics';
import { NAV_MODES, trackNavAction } from '~/containers/Header/analytics';

const THUMB_WIDTH = 100;

const imgixParams = {
  ...commonParams,
  ar: squareAr,
  fit: 'crop',
  w: THUMB_WIDTH,
  bg: 'fff'
};

export default function NestLevel({
  index,
  blockName = '',
  data = {},
  children = null,
  final = false,
  hierarchy
}) {
  const {
    taxon_id: taxonId = null,
    name = null,
    taxon_image: imgSrc,
    relative_url: relativeUrl = null,
    categories = []
  } = data;

  const [error, setError] = useState(false);
  const [burgerBrokenImages, setBurgerBrokenImages] = useRecoilState(
    atoms.burgerBrokenImages
  );

  const linkClass = useMemo(
    () =>
      [
        `${blockName}__link`,
        'u-t-body',
        'u-t-nolined',
        'u-t-nooutline',
        'is-still'
      ].join(' '),
    [blockName]
  );

  const isEndNode = useMemo(
    () => final || !categories.length,
    [categories.length, final]
  );

  const cleanSrc = useMemo(() => cleanUrlToImgix(imgSrc), [imgSrc]);

  const fallbackImgUrl = useMemo(
    () => buildURL(placeholdSrc, { ...imgixParams, w: THUMB_WIDTH * 0.1 }),
    []
  );

  const rowContent = useMemo(
    () => (
      <figure className={`${blockName}__box`}>
        <div className={`${blockName}__thumb`}>
          <span className={`${blockName}__cast`} />
          <Imgix
            className={`${blockName}__img u-block lazyload`}
            sizes={`${THUMB_WIDTH}px`}
            disableSrcSet
            src={error ? notFoundSrc : cleanSrc}
            imgixParams={imgixParams}
            attributeConfig={lazyAttribs}
            htmlAttributes={{
              alt: name,
              src: `${fallbackImgUrl}`,
              onError: () => setError(true)
            }}
          />
        </div>
        <figcaption className={`${blockName}__caption`}>{name}</figcaption>
      </figure>
    ),
    [blockName, cleanSrc, error, fallbackImgUrl, name]
  );

  const handleClick = useCallback(
    (ev) => {
      if (!isEndNode) {
        ev.preventDefault();
      } else {
        trackNavAction(ga4Events?.nav_click_link, {
          mode: NAV_MODES?.accordion_link,
          path: relativeUrl,
          hierarchy
        });
      }
    },
    [hierarchy, isEndNode, relativeUrl]
  );

  useEffect(() => {
    if (error && burgerBrokenImages.indexOf(index) === -1) {
      trackBrokenImage({
        label: `Nav - ${name}`,
        itemLocation: hitLocations?.burger_nav_accordion,
        listPosition: index
      });
      setBurgerBrokenImages((prev) => uniq([...prev, index]));
    }
  }, [burgerBrokenImages, error, index, name, setBurgerBrokenImages]);

  if (isEndNode)
    return (
      <div className={`${blockName}__btn`}>
        <a
          className={linkClass}
          href={relativeUrl}
          data-nvid={taxonId}
          onClick={handleClick}
        >
          {rowContent}
        </a>
        <span className={`${blockName}__act u-inline-block`} />
      </div>
    );

  return (
    <AccordionItem uuid={relativeUrl} className={`${blockName}__item`}>
      <AccordionItemState>
        {({ expanded }) => (
          <div
            className={classNames(`${blockName}__shade`, {
              'has-shade': expanded
            })}
          >
            <AccordionItemHeading className={`${blockName}__head`}>
              <AccordionItemButton
                className={classNames(
                  `${blockName}__btn u-p-pointer u-t-nooutline`,
                  {
                    'is-open': expanded
                  }
                )}
              >
                <a
                  className={linkClass}
                  href={relativeUrl}
                  data-nvid={taxonId}
                  onClick={handleClick}
                >
                  {rowContent}
                </a>
                <span className={`${blockName}__act u-inline-block`}>
                  <span
                    className={classNames(
                      `${blockName}__icon u-t-body u-animate-all ic-bef ic-xbld ic-site-arrow ic-xbld ic-dir-down`,
                      {
                        'is-open': expanded
                      }
                    )}
                  />
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={`${blockName}__panel`}>
              {children}
            </AccordionItemPanel>
          </div>
        )}
      </AccordionItemState>
    </AccordionItem>
  );
}
