import createInstance from './axiosInstance';
import commonRequests from './commonRequests';

const instanceV1 = createInstance();
const instanceV2 = createInstance('v2');
const instanceV3 = createInstance('v3');

export const SUCCESS_CODE = 200;

export const { errorHandler } = commonRequests;

export const sendGet = (path, queries = {}, options = {}) =>
  commonRequests.sendGet(instanceV1, path, queries, options);

export const sendPost = (path, body, options = {}) =>
  commonRequests.sendPost(instanceV1, path, body, options);

export const submit = (path, body, options = {}) =>
  commonRequests.submit(instanceV1, path, body, options);

export const sendDelete = (path, options = {}) =>
  commonRequests.sendDelete(instanceV1, path, options);

export const sendGetV2 = (path, queries = {}, options = {}) =>
  commonRequests.sendGet(instanceV2, path, queries, options);

export const sendPostV2 = (path, body, options = {}) =>
  commonRequests.sendPost(instanceV2, path, body, options);

export const sendDeleteV2 = (path, options = {}) =>
  commonRequests.sendDelete(instanceV2, path, options);

export const sendGetV3 = (path, queries = {}, options = {}) =>
  commonRequests.sendGet(instanceV3, path, queries, options);

export const sendPostV3 = (path, body, options = {}) =>
  commonRequests.sendPost(instanceV3, path, body, options);

export const sendDeleteV3 = (path, options = {}) =>
  commonRequests.sendDelete(instanceV3, path, options);
