import React from 'react';

import NavBaseOptionsList from '~/components/Header/NavBaseOptions/List';
import NavAccordionMenu from '~/components/Header/NavAccordionMenu';
import BurgerLinks from '~/components/Header/NavBurgerMenu/BurgerLinks';
import BurgerSocials from '~/components/Header/NavBurgerMenu/BurgerSocials';
import './NavBurgerMenu.scss';

export default function NavBurgerMenu() {
  return (
    <nav className="c-nvBurger">
      <div className="c-nvBurger__flex">
        <div className="c-nvBurger__list">
          <NavBaseOptionsList>
            <NavAccordionMenu />
          </NavBaseOptionsList>
        </div>
        <div className="c-nvBurger__other">
          <BurgerLinks />
          <div className="c-nvBurger__gap" />
          <BurgerSocials />
        </div>
      </div>
    </nav>
  );
}
