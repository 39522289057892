import { useCallback, useMemo } from 'react';
import { noop } from 'lodash';
import { useRecoilValue } from 'recoil';

import airbrake from '~/libs/airbrake';
import {
  LOGIN_API,
  SIGNUP_API,
  AUTH_MODE
} from '~/containers/ModalsManager/constants';
import {
  trackClickAuthEmailSubmit,
  trackAuthResponse,
  trackAuthLead
} from '~/utils/analytics/gtm';
import { submit } from '~/utils/requestAPI';
import atoms from '~/containers/ModalsManager/states/atoms';

const AUTH_PROVIDER = 'native';

export default function useAuthRequest({
  mode = AUTH_MODE?.SIGNUP,
  setIsSubmitting = noop,
  setError = noop,
  handleClose = noop
}) {
  const authConfig = useRecoilValue(atoms.authConfig);

  const isSignIn = useMemo(() => mode === AUTH_MODE?.LOGIN, [mode]);

  const handleAuth = useCallback(
    async (formData) => {
      try {
        setIsSubmitting(true);
        trackClickAuthEmailSubmit();
        const path = isSignIn ? LOGIN_API : SIGNUP_API;
        const {
          current_email: currentEmail,
          current_password: currentPassword,
          newsletter_subscription: newsletterSubscription,
          ...rest
        } = formData;
        const body = {
          'customer[email]': currentEmail,
          'customer[password]': currentPassword,
          ...rest
        };
        if (!isSignIn) body.newsletter_subscription = newsletterSubscription;

        const { data } = await submit(path, body);
        if (data) {
          const { success, message } = data;
          if (success) {
            trackAuthLead({
              mode,
              content: authConfig?.authContent,
              authProvider: AUTH_PROVIDER
            });
            trackAuthResponse({
              authProvider: AUTH_PROVIDER,
              response: 'success'
            });
            handleClose();
            window.location.reload();
          } else {
            trackAuthResponse({
              authProvider: AUTH_PROVIDER,
              response: `invalid - ${message}`
            });
            setError(message);
          }
        }
      } catch (error) {
        trackAuthResponse({
          authProvider: AUTH_PROVIDER,
          response: 'failure'
        });
        airbrake.notify({ error });
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      setIsSubmitting,
      isSignIn,
      mode,
      authConfig?.authContent,
      handleClose,
      setError
    ]
  );

  return handleAuth;
}
