import React, { useCallback } from 'react';
import classNames from 'classnames';

import useNavBaseOptions from '~/hooks/Header/useNavBaseOptions';
import { humanListCount } from '~/utils/helper';
import { hitLocations } from '~/utils/analytics/tracking';
import { trackBaseLinkClick } from '~/containers/Header/analytics';
import './Links.scss';

export default function NavBaseOptionsLinks() {
  const paths = useNavBaseOptions();

  const handleClick = useCallback(
    (label, path, index) =>
      trackBaseLinkClick({
        label,
        url: path,
        listPosition: humanListCount(index),
        location: hitLocations?.top_nav_base
      }),
    []
  );

  return (
    <div className="c-nvPageLinks u-inline-block">
      {paths.map(
        (
          { path, title, stickyLabel, hasNew = false, isActive = false },
          idx
        ) => (
          <a
            key={stickyLabel}
            className={classNames(
              'c-nvPageLinks__link u-inline-block u-t-nolined is-still',
              {
                'is-active': isActive,
                [`is-${stickyLabel.toLowerCase()}`]: !!stickyLabel
              }
            )}
            href={path}
            title={title}
            onClick={() => handleClick(stickyLabel, path, idx)}
          >
            <span className="c-nvPageLinks__label">
              {stickyLabel}
              {hasNew && (
                <span className="c-nvPageLinks__new u-inline-block u-t-white u-b-secondary">
                  NEW
                </span>
              )}
            </span>
          </a>
        )
      )}
    </div>
  );
}
