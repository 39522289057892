/* eslint-disable react/no-array-index-key */

import React, { useMemo, useCallback } from 'react';
import queryString from 'query-string-for-all';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isEmpty, isFunction } from 'lodash';

import { ACCOUNT_PATHS } from '~/containers/Account/constants';
import { AUTH_MODE, AUTH_CONTENT } from '~/containers/ModalsManager/constants';
import atoms from '~/containers/shared/states/atoms';
import headerAtoms from '~/containers/Header/states/atoms';
import modalManagerAtoms from '~/containers/ModalsManager/states/atoms';
import { hitLocations } from '~/utils/analytics/tracking';
import {
  ABOUT_US_PATH,
  FAQ_PATH,
  FAQS_TOP_HASH,
  FAQS_RETURNS_HASH,
  CONTACT_US_PATH,
  TERMS_CONDITIONS_PATH,
  BUSINESS_PATH,
  REVIEWS_PATH,
  JOBS_PATH
} from '~/utils/pagePaths';
import './BurgerLinks.scss';

export default function BurgerLinks() {
  const loggedUser = useRecoilValue(atoms.currentCustomer);
  const setBurgerOpen = useSetRecoilState(headerAtoms.burgerOpen);
  const authConfigUpdate = useSetRecoilState(
    modalManagerAtoms.authConfigUpdate
  );

  const handleRefer = useCallback(() => {
    setBurgerOpen(false);
    authConfigUpdate({
      authMode: AUTH_MODE?.LOGIN,
      authContent: AUTH_CONTENT?.REFERRAL,
      location: hitLocations?.burger_nav_referral
    });
  }, [authConfigUpdate, setBurgerOpen]);

  const quickLinks = useMemo(
    () => [
      [
        {
          label: 'About',
          path: ABOUT_US_PATH
        },
        {
          label: 'Contact',
          path: CONTACT_US_PATH
        },
        {
          label: 'Returns',
          path: queryString.stringifyUrl({
            url: FAQ_PATH,
            fragmentIdentifier: FAQS_RETURNS_HASH
          })
        },
        {
          label: 'Shipping',
          path: queryString.stringifyUrl({
            url: FAQ_PATH,
            fragmentIdentifier: FAQS_TOP_HASH
          })
        },
        {
          label: 'FAQ',
          path: FAQ_PATH
        }
      ],
      [
        {
          label: 'Reviews',
          path: REVIEWS_PATH
        },
        {
          label: 'Referral',
          path: ACCOUNT_PATHS.referrals.path,
          customHandler: isEmpty(loggedUser) ? handleRefer : undefined
        },

        {
          label: 'HipVan for Business',
          path: BUSINESS_PATH
        },
        {
          label: 'Terms',
          path: TERMS_CONDITIONS_PATH
        },
        {
          label: 'Jobs',
          path: JOBS_PATH
        }
      ]
    ],
    [handleRefer, loggedUser]
  );

  return (
    <section className="c-nvBlinks">
      {quickLinks.map((column, listId) => (
        <ul key={listId} className="c-nvBlinks__list u-minied-list">
          {column.map(({ path, label, customHandler }, linkId) => (
            <li key={linkId} className="c-nvBlinks__item">
              {isFunction(customHandler) ? (
                <div
                  className="c-nvBlinks__link u-block u-t-body u-p-pointer"
                  role="button"
                  tabIndex={-1}
                  onClick={customHandler}
                >
                  {label}
                </div>
              ) : (
                <a
                  className="c-nvBlinks__link u-block u-t-body u-t-nolined u-t-nooutline is-still"
                  href={path}
                >
                  {label}
                </a>
              )}
            </li>
          ))}
        </ul>
      ))}
    </section>
  );
}
