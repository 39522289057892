import {
  gtmTriggers,
  ga4Events,
  uaEventGa4,
  customEventGa4,
  pagePathQueryFragment
} from '~/utils/analytics/gtm';
import { amplitudeTrack } from '~/utils/analytics/amplitude';

const NAV_MODES = {
  roots_slider: 'roots_slider',
  accordion_menu: 'accordion_menu',
  accordion_link: 'accordion_link',
  dropdown_menu: 'dropdown_menu',
  dropdown_link: 'dropdown_link'
};

const NAV_HIERARCHY = {
  root: 'root',
  main: 'main',
  sub: 'sub'
};

const trackBaseLinkClick = ({
  label = '-',
  url = '-',
  listPosition,
  location = 'unknown_trackBaseLinkClick'
} = {}) =>
  uaEventGa4({
    uaCategory: 'Nav Check',
    uaAction: [listPosition, label, url].filter((n) => !!n).join(' :: '),
    uaLabel: pagePathQueryFragment(),
    uaValue: location
  });

const trackNavBurgerOpen = () => {
  const ga4Payload = {
    referrer: pagePathQueryFragment()
  };
  customEventGa4(gtmTriggers?.shopNav, ga4Events?.nav_view_burger, ga4Payload);
  amplitudeTrack(ga4Events?.nav_view_burger, ga4Payload);
};

const trackNavAction = (
  eventName = 'unknown_trackNavAction',
  { mode = 'unknown_trackNavAction', path, hierarchy } = {}
) => {
  const ga4Payload = {
    mode,
    url: path,
    location: hierarchy,
    referrer: pagePathQueryFragment()
  };
  customEventGa4(gtmTriggers?.shopNav, eventName, ga4Payload);
  amplitudeTrack(eventName, ga4Payload);
};

const trackMarqueeClick = ({ position, title, url } = {}) => {
  const ga4Payload = {
    list_position: position,
    title,
    url,
    location: pagePathQueryFragment()
  };
  customEventGa4(
    gtmTriggers?.shopCalloutMarquee,
    ga4Events?.click_callout_marquee,
    ga4Payload
  );
  amplitudeTrack(ga4Events?.click_callout_marquee, ga4Payload);
};

export {
  NAV_MODES,
  NAV_HIERARCHY,
  trackBaseLinkClick,
  trackNavBurgerOpen,
  trackNavAction,
  trackMarqueeClick
};
