import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useMediaQuery } from 'react-responsive';
import { useCookies } from 'react-cookie';
import { isEmpty } from 'lodash';

import { mediaQueryPreset } from '~/containers/shared/constants';
import { PICTURES_NEW_PATH } from '~/containers/Blog/constants';
import {
  APP_NUDGE_PAUSE_KEY,
  LEADSUB_PAUSE_KEY,
  LEADSUB_SUCCESS_KEY,
  REQUEST_INFO_LATER_KEY,
  STICKY_PROMO_RATIO_W_MOBL,
  STICKY_PROMO_RATIO_W_DESK,
  RECOVERY_CHECK_DELAY
} from '~/containers/ModalsManager/constants';
import sharedAtoms from '~/containers/shared/states/atoms';
import atoms from '~/containers/ModalsManager/states/atoms';
import ErrorBoundary from '~/components/ErrorBoundary';
import AccountInfoModal from '~/containers/ModalsManager/AccountInfoModal';
import ProfileInfoModal from '~/containers/ModalsManager/ProfileInfoModal';
import ModalAppNudge from '~/components/ModalAppNudge';
import ModalExitIntent from '~/components/ModalExitIntent';
import ModalLeadSub from '~/components/ModalLeadSub';
import ModalStickyPromo from '~/components/ModalStickyPromo';
import useCalloutsData from '~/hooks/shared/useCalloutsData';
import useWindowWidth from '~/hooks/shared/useWindowWidth';
import useExitIntent from '~/hooks/shared/useExitIntent';
import { GLOBAL_BASE_PX } from '~/utils/modularScale';
import { localStorageHelper } from '~/utils/storageHelper';
import { allowZdWidget, toggleZdWidget } from '~/utils/zendeskControls';
import { defaultForUndefinedOrNull } from '~/utils/helper';
import { blockZendeskPath } from '~/utils/Blog/helper';
import './RequestInfoModal.scss';

export default function View() {
  const isMobile = useMediaQuery(mediaQueryPreset.mobile);
  const viewportWidth = useWindowWidth();

  const [{ pathname = '', search = '' }, setLoc] = useState({});

  const pageUnsafe = useMemo(
    () =>
      globalThis.isMobileApp ||
      ['auto_auth', 'platform=mobile-app']
        .map((param) => search.indexOf(param) > -1)
        .some((v) => v === true) ||
      [
        PICTURES_NEW_PATH,
        '/checkouts/',
        '/404',
        '/faq',
        '/covid-19',
        '/passwords/',
        '/feedbacks'
      ]
        .map((path) => pathname.indexOf(path) > -1)
        .some((v) => v === true),
    [pathname, search]
  );

  // --------------------
  // Callouts Flow
  // --------------------

  const [readyForCallouts, setReadyForCallouts] = useRecoilState(
    atoms.readyForCallouts
  );
  const [triedRecoveryCallout, setTriedRecoveryCallout] = useRecoilState(
    atoms.triedRecoveryCallout
  );
  const [showStickyPromo, setShowStickyPromo] = useRecoilState(
    atoms.showStickyPromo
  );
  const [showAppNudge, setShowAppNudge] = useState(false);
  const [showLeadSub, setShowLeadSub] = useState(false);
  const [showExitIntent, setShowExitIntent] = useState(false);

  const {
    stickyPromoData,
    appNudgeConfig,
    appNudgeData,
    leadSubConfig,
    leadSubData,
    exitIntentData
  } = useCalloutsData();

  const {
    registerHandler: registerExitIntentHandler,
    unsubscribe: unsubExitIntent,
    resetState: resetExitIntent
  } = useExitIntent(exitIntentData?.config);

  registerExitIntentHandler({
    id: 'exitIntentModal',
    handler: () => {
      if (isEmpty(exitIntentData) || !triedRecoveryCallout) return;
      setShowExitIntent(true);
    }
  });

  const handleUnsubExitIntent = useCallback(
    () => unsubExitIntent(),
    [unsubExitIntent]
  );

  // --------------------
  // Info Request Flow
  // --------------------

  const {
    requested_update_info: alreadyRequested = true,
    auth_user_id: userId = ''
  } = useRecoilValue(sharedAtoms.currentCustomer);

  const [showAccountModal, setShowAccountModal] = useRecoilState(
    atoms.showAccountInfoModal
  );

  const pausedRequestCookie = useMemo(
    () => (isEmpty(userId) ? '' : `${REQUEST_INFO_LATER_KEY}/${userId}`),
    [userId]
  );

  const [cookies] = useCookies([
    pausedRequestCookie,
    APP_NUDGE_PAUSE_KEY,
    LEADSUB_PAUSE_KEY
  ]);

  // --------------------
  // Combined Flow
  // --------------------

  const triggerApplicableCallouts = useCallback(() => {
    // If NO NEED for user info request,
    if (!!cookies?.[pausedRequestCookie] || alreadyRequested) {
      // Ready for any marketing callouts
      setReadyForCallouts(true);
      return;
    }

    // If NEED for user info request,
    if (!alreadyRequested) setShowAccountModal(true);
  }, [
    alreadyRequested,
    cookies,
    pausedRequestCookie,
    setReadyForCallouts,
    setShowAccountModal
  ]);

  useEffect(
    () => setLoc(defaultForUndefinedOrNull(globalThis?.location, {})),
    []
  );

  useEffect(() => {
    if (pageUnsafe) {
      setReadyForCallouts(false);
    } else {
      triggerApplicableCallouts();
    }
  }, [isMobile, pageUnsafe, setReadyForCallouts, triggerApplicableCallouts]);

  const overlayPresent = useMemo(
    () =>
      !readyForCallouts ||
      showExitIntent ||
      (isMobile && showAppNudge) ||
      (!isMobile && showLeadSub),
    [isMobile, readyForCallouts, showAppNudge, showExitIntent, showLeadSub]
  );

  // --------------------
  // Sales Recovery Check
  // --------------------

  const noRecovery = useMemo(
    () =>
      (isMobile && isEmpty(appNudgeConfig)) ||
      (!isMobile && isEmpty(leadSubConfig)),
    [appNudgeConfig, isMobile, leadSubConfig]
  );

  const skipRecovery = useMemo(
    () =>
      (isMobile && !!cookies?.[APP_NUDGE_PAUSE_KEY]) ||
      (!isMobile &&
        (!!cookies?.[LEADSUB_PAUSE_KEY] ||
          localStorageHelper.getItem(LEADSUB_SUCCESS_KEY))),
    [cookies, isMobile]
  );

  useEffect(() => {
    let lazyRecoveryCheck = null;

    lazyRecoveryCheck = setTimeout(() => {
      if (noRecovery || skipRecovery) {
        setTriedRecoveryCallout(true);
        resetExitIntent();
      }
    }, RECOVERY_CHECK_DELAY);

    return () => {
      clearTimeout(lazyRecoveryCheck);
    };
  }, [noRecovery, resetExitIntent, setTriedRecoveryCallout, skipRecovery]);

  // --------------------
  // Zendesk Visibility
  // --------------------

  const scrollToTopOffset = useMemo(() => {
    const ratio = isMobile
      ? STICKY_PROMO_RATIO_W_MOBL
      : STICKY_PROMO_RATIO_W_DESK;

    const { presets = {} } = defaultForUndefinedOrNull(
      globalThis?.HV?.Components?.GotoTop,
      {}
    );

    return showStickyPromo
      ? viewportWidth / ratio + GLOBAL_BASE_PX
      : presets?.zd_high;
  }, [isMobile, showStickyPromo, viewportWidth]);

  const { someOpen, allClose } = useMemo(() => {
    const modalOpenStates = [
      showAccountModal,
      showStickyPromo,
      isMobile && showAppNudge,
      !isMobile && showLeadSub,
      showExitIntent
    ];
    return {
      someOpen: modalOpenStates.some((v) => v === true),
      allClose: modalOpenStates.every((v) => v === false) && !blockZendeskPath()
    };
  }, [
    isMobile,
    showAccountModal,
    showAppNudge,
    showExitIntent,
    showLeadSub,
    showStickyPromo
  ]);

  useEffect(() => {
    const toggleZendeskWidget = () => {
      if (someOpen) {
        allowZdWidget(false);
        toggleZdWidget(false, true, scrollToTopOffset);
      }

      if (allClose) {
        allowZdWidget(true);
        toggleZdWidget(true);
      }
    };

    let lazyUpdateToggle = null;
    lazyUpdateToggle = setTimeout(toggleZendeskWidget, 1000);

    return () => {
      clearTimeout(lazyUpdateToggle);
    };
  }, [allClose, scrollToTopOffset, someOpen]);

  return (
    <ErrorBoundary>
      <>
        <AccountInfoModal pathname={pathname} />
        <ProfileInfoModal pathname={pathname} />

        {readyForCallouts && (
          <>
            <ModalStickyPromo
              data={stickyPromoData}
              visible={showStickyPromo}
              setVisible={setShowStickyPromo}
              covered={overlayPresent}
            />
            <ModalExitIntent
              data={exitIntentData}
              visible={showExitIntent}
              setVisible={setShowExitIntent}
              handleUnsub={handleUnsubExitIntent}
            />
            {isMobile ? (
              <ModalAppNudge
                data={appNudgeData}
                visible={showAppNudge}
                setVisible={setShowAppNudge}
              />
            ) : (
              <ModalLeadSub
                data={leadSubData}
                visible={showLeadSub}
                setVisible={setShowLeadSub}
              />
            )}
          </>
        )}
      </>
    </ErrorBoundary>
  );
}
