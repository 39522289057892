const CNC_LAUNCH_CMS_PAGE_PATH = '/home-styling-community';
const SHOPPING_APP_CMS_PAGE_PATH = '/hipvan-shopping-app';
const MATTRESS_CMS_PAGE_PATH = '/mattresses-by-hipvan';
const SHOWROOM_CMS_PAGE_PATH = '/pages/hipvan-atelier-popup';
const NHS_CMS_PAGE_PATH = '/new-homeowner-special';
const BUNDLES_CMS_PAGE_PATH = '/pages/bundle-and-save';
const ABOUT_US_PATH = '/pages/about-us';
const CONTACT_US_PATH = '/pages/contact-us';
const FAQ_PATH = '/pages/faqs';
const TERMS_CONDITIONS_PATH = '/pages/terms-conditions';
const BUSINESS_PATH = '/business';
const PARTNERS_PATH = '/partners';
const REVIEWS_PATH = '/hipvan-reviews';
const JOBS_PATH = '/pages/jobs';
const RETURN_FORM_PATH = '/online-return-form';
const PRODUCT_REVIEWS_PATH = '/product-reviews';

const FAQS_TOP_HASH = 'top';
const FAQS_RETURNS_HASH = 'returns';
const FAQS_REFERRAL_HASH = 'referral-program';

export {
  CNC_LAUNCH_CMS_PAGE_PATH,
  SHOPPING_APP_CMS_PAGE_PATH,
  MATTRESS_CMS_PAGE_PATH,
  SHOWROOM_CMS_PAGE_PATH,
  NHS_CMS_PAGE_PATH,
  BUNDLES_CMS_PAGE_PATH,
  ABOUT_US_PATH,
  CONTACT_US_PATH,
  TERMS_CONDITIONS_PATH,
  BUSINESS_PATH,
  PARTNERS_PATH,
  REVIEWS_PATH,
  JOBS_PATH,
  RETURN_FORM_PATH,
  PRODUCT_REVIEWS_PATH,
  FAQ_PATH,
  FAQS_TOP_HASH,
  FAQS_RETURNS_HASH,
  FAQS_REFERRAL_HASH
};
