import keyMirror from 'keymirror';

const DEFAULT_NAV_HEIGHT = 80;
const NAV_TOP_THRESHOLD = 200;

const atomKeys = keyMirror({
  NAVIGATION: null,
  ROOT_HOVER: null,
  BURGER_OPEN: null,
  BURGER_BROKEN_IMAGES: null,
  RECALC_ON_FONTS_LOAD: null,
  LIST_FILTER_OPEN: null,
  SEARCH_FOCUS: null,
  DROPDOWN_OPEN: null,
  CART_SESSIONS: null,
  NAV_SEARCH_QUERY: null,
  KLEVU_NAV_SEARCH_RESULTS: null,
  KLEVU_POPULAR_TERMS: null,
  SEARCH_SUGGESTIONS: null
});

const selectorKeys = keyMirror({
  TOTAL_ITEMS_IN_CART: null,
  NAV_SEARCH_SUGGESTION_LIST: [],
  SEARCH_FOCUS_DROPDOWN_OPEN: null
});

const HELMET_CONTEXT = {};

const keyboardKeys = keyMirror({
  Enter: null,
  ArrowUp: null,
  ArrowDown: null,
  Tab: null
});

const SEARCH_SUGGESTIONS_API = '/search/suggestions';

export {
  atomKeys,
  selectorKeys,
  keyboardKeys,
  HELMET_CONTEXT,
  DEFAULT_NAV_HEIGHT,
  NAV_TOP_THRESHOLD,
  SEARCH_SUGGESTIONS_API
};
