/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading */

import { delay, isEmpty, isFinite, noop } from 'lodash';
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import { mediaQueryPreset } from '~/containers/shared/constants';
import { GLOBAL_BASE_PX } from '~/utils/modularScale';
import { trackMarqueeClick } from '~/containers/Header/analytics';
import './NewsTicker.scss';

// const TickerStates = Object.freeze({
//   Stopped: 'stopped',
//   Running: 'running',
//   Paused: 'paused'
// });
const Courses = Object.freeze({ PREV: 1, NEXT: 2 });
export const Directions = Object.freeze({ UP: 1, DOWN: 2 });

const ITEM_CLASSNAMES = 'c-marq__item u-block u-t-small u-animate-all';
const DEFAULT_ROW_HEIGHT = GLOBAL_BASE_PX * 1.5;
const DEFAULT_INTERVAL = 2500;
const DEFAULT_ANIMATION = 400;
const ANIMATE_TOLERANCE = 25;

function WrapTag({
  position = 'unknown_NewsTicker',
  safeInnerHtml = {
    __html: ''
  },
  link,
  title
} = {}) {
  const handleClick = useCallback(() => {
    const { __html: htmlContent } = safeInnerHtml;

    trackMarqueeClick({
      position,
      title: htmlContent,
      url: link
    });
  }, [link, position, safeInnerHtml]);

  if (!link)
    return (
      <div
        role="button"
        tabIndex={-1}
        title={title}
        className={ITEM_CLASSNAMES}
        dangerouslySetInnerHTML={safeInnerHtml}
        onClick={handleClick}
      />
    );

  return (
    <a
      href={link}
      title={title}
      className={ITEM_CLASSNAMES}
      dangerouslySetInnerHTML={safeInnerHtml}
      onClick={handleClick}
    />
  );
}

export default function NewsTicker({
  list = [],
  maxRows = 1,
  direction = Directions.UP,
  duration = DEFAULT_INTERVAL,
  speed = DEFAULT_ANIMATION,
  autoStart = true,
  pauseOnHover = true,
  started = noop,
  stopped = noop,
  paused = noop,
  unpaused = noop,
  movingDown = noop,
  hasMoved = noop,
  movingUp = noop
}) {
  const tickerRef = useRef(null);
  const [items, setItems] = useState(list);
  const [ready, setReady] = useState(false);
  const [speedState, setSpeedState] = useState(0);
  const [pausedState, setPausedState] = useState(false);
  const [moving, setMoving] = useState(false);
  const intervalRef = useRef(null);

  const itemPosition = useCallback(
    (currentId) => list.findIndex((i) => i?.id === currentId),
    [list]
  );

  // const getState = useCallback(() => {
  //   if (pausedState) return TickerStates.Paused;
  //   return ready ? TickerStates.Running : TickerStates.Stopped;
  // }, [ready, pausedState]);

  // --------------------
  // Ticker Height
  // --------------------

  const isMobile = useMediaQuery(mediaQueryPreset.mobile);
  const testsRef = useRef({});
  const [rowHeight, setRowHeight] = useState(DEFAULT_ROW_HEIGHT);

  const handleRefs = useCallback((el, id) => {
    testsRef.current = {
      ...testsRef.current,
      [id]: el
    };
  }, []);

  useEffect(() => {
    const reRender = setTimeout(() => {
      if (!isEmpty(testsRef?.current)) {
        const heights = Object.values(testsRef?.current)
          .map((node) => node?.clientHeight)
          .filter((v) => !!isFinite(v));
        setRowHeight(Math.max(DEFAULT_ROW_HEIGHT, ...heights));
      }
    }, 0);

    return () => clearTimeout(reRender);
  }, [items, isMobile]);

  // --------------------
  // Ticker Movement
  // --------------------

  const handleDownAnimation = useCallback(
    async (newList) => {
      if (tickerRef?.current) {
        const firstNode = tickerRef.current.children[0];

        setItems(newList);
        // Hide first li element at up
        firstNode.style.cssText = `margin: -${rowHeight}px 0 0 0`;

        // First element will go down in speed ms
        firstNode.style.cssText = `margin: 0;transition: margin ${speed}ms;`;

        // Wait for speed ms and add last element to beginning of the list.
        await new Promise((resolve) => {
          delay(async () => {
            newList.pop();
            setItems(newList);
            resolve();
          }, speed);
        });
      }
    },
    [rowHeight, speed]
  );

  const handleUpAnimation = useCallback(
    async (newList) => {
      if (tickerRef?.current) {
        const firstNode = tickerRef.current.children[0];
        setItems(newList);

        // First element will go up rowHeight px in speed ms
        firstNode.style.cssText = `margin: -${rowHeight}px 0 0 0; transition: all ${speed}ms;`;

        // Wait for speed ms and send first element to end of the list.
        // After that get first list element back to margin 0.
        await new Promise((resolve) => {
          delay(async () => {
            newList.shift();
            setItems(newList);
            firstNode.style.cssText = 'margin: 0';
            resolve();
          }, speed);
        });
      }
    },
    [rowHeight, speed]
  );

  const moveUp = useCallback(async () => {
    if (moving) return;

    setMoving(true);
    movingUp();

    const itemsCopy = items.slice(0);
    itemsCopy.push(itemsCopy[0]);

    await handleUpAnimation(itemsCopy);

    setMoving(false);
    hasMoved();
  }, [handleUpAnimation, hasMoved, items, moving, movingUp]);

  const moveDown = useCallback(async () => {
    if (moving) return;

    setMoving(true);
    movingDown();

    const itemsCopy = items.slice(0);
    itemsCopy.unshift(itemsCopy[itemsCopy.length - 1]);

    await handleDownAnimation(itemsCopy);

    setMoving(false);
    hasMoved();
  }, [handleDownAnimation, hasMoved, items, moving, movingDown]);

  const moveTo = useCallback(
    (course) =>
      (course === Courses.NEXT && direction === Directions.DOWN) ||
      (course === Courses.PREV && direction === Directions.UP)
        ? moveDown()
        : moveUp(),
    [direction, moveDown, moveUp]
  );

  const handlePause = useCallback(
    (shouldPause) => {
      if (!(pauseOnHover && ready)) return;
      if (pausedState !== shouldPause) setPausedState(shouldPause);
    },
    [pauseOnHover, pausedState, ready]
  );

  useEffect(() => {
    if (pausedState) paused();
    if (!pausedState) unpaused();
  }, [paused, pausedState, unpaused]);

  // --------------------
  // Ticker Mount
  // --------------------

  const start = useCallback(() => {
    if (ready) return;

    setItems(list);
    setReady(true);
  }, [list, ready]);

  // const stop = useCallback(() => {
  //   if (!ready) return;

  //   clearInterval(intervalRef.current);
  //   setReady(false);
  // }, [ready, intervalRef]);

  const moveToNext = useCallback(() => {
    if (!pausedState) moveTo(Courses.NEXT);
  }, [moveTo, pausedState]);

  const resetInterval = useCallback(() => {
    if (!ready) return;

    clearInterval(intervalRef.current);
    if (globalThis.isClient) {
      intervalRef.current = setInterval(() => moveToNext(), duration);
    }
  }, [ready, duration, moveToNext, intervalRef]);

  const checkSpeed = useCallback(() => {
    if (duration < speedState + ANIMATE_TOLERANCE)
      setSpeedState(duration - ANIMATE_TOLERANCE);
  }, [duration, speedState]);

  const init = useCallback(() => {
    if (tickerRef?.current) {
      tickerRef.current.style.height = `${rowHeight * maxRows}px`;
      tickerRef.current.style.overflow = 'hidden';
      checkSpeed();

      if (autoStart) start();
    }
  }, [autoStart, checkSpeed, maxRows, rowHeight, start]);

  useEffect(() => {
    if (tickerRef?.current) init();
  }, [init]);

  useEffect(() => {
    if (ready || !isEmpty(items)) {
      resetInterval();
      started();
    }
  }, [ready, items, resetInterval, started]);

  // --------------------
  // Ticker Unmount
  // --------------------

  useEffect(() => {
    if (!ready) stopped();
  }, [ready, stopped]);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [intervalRef]);

  if (items?.length === 1) {
    const onlyItem = items?.[0];

    return (
      <div className="c-marq u-b-primary">
        <ul className="c-marq__list u-minied-list">
          <li className="c-marq__child">
            <WrapTag
              position={onlyItem?.position}
              safeInnerHtml={onlyItem?.safeInnerHtml}
              link={onlyItem?.link}
              title={onlyItem?.tooltip}
            />
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div className={`c-marq u-b-primary ${moving ? 'is-moving' : ''}`}>
      {rowHeight && (
        <ul
          className="c-marq__list u-minied-list"
          ref={tickerRef}
          onMouseEnter={() => handlePause(true)}
          onMouseLeave={() => handlePause(false)}
        >
          {items.map((props, idx) => (
            <li
              // eslint-disable-next-line react/no-array-index-key
              key={`${idx}-${itemPosition(props?.id)}`}
              className="c-marq__child"
            >
              <WrapTag
                position={props?.position}
                safeInnerHtml={props?.safeInnerHtml}
                link={props?.link}
                title={props?.tooltip}
              />
            </li>
          ))}
        </ul>
      )}

      <div className="c-marq__test">
        {items.map((props, idx) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`${props?.id}-${idx}`}
            ref={(el) => handleRefs(el, idx)}
            className={ITEM_CLASSNAMES}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={props?.safeInnerHtml}
          />
        ))}
      </div>
    </div>
  );
}
