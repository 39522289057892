import React, { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Accordion } from 'react-accessible-accordion';

import atoms from '~/containers/Header/states/atoms';
import NestLevel from '~/components/Header/NavAccordionMenu/NestLevel';
import { humanListCount } from '~/utils/helper';
import {
  NAV_MODES,
  NAV_HIERARCHY,
  trackNavAction
} from '~/containers/Header/analytics';
import { ga4Events } from '~/utils/analytics/gtm';
import './NavAccordionMenu.scss';

const ROOT_BLOCK = 'c-nvAccordRoot';
const MAIN_BLOCK = 'c-nvAccordMain';
const SUB_BLOCK = 'c-nvAccordSub';

export default function NavAccordionMenu() {
  const navTree = useRecoilValue(atoms.navigation);

  const handleExpand = useCallback((val, hierarchy) => {
    const href = val[0];
    if (href) {
      trackNavAction(ga4Events?.nav_view_menu, {
        mode: NAV_MODES?.accordion_menu,
        path: href,
        hierarchy
      });
    }
  }, []);

  return (
    <Accordion
      className={ROOT_BLOCK}
      allowZeroExpanded
      onChange={(val) => handleExpand(val, NAV_HIERARCHY?.root)}
    >
      {navTree.map((rootNode, rootIdx) => {
        const { id: rootKey, categories: mainCats = [] } = rootNode;
        const rootPos = humanListCount(rootIdx);
        return (
          <NestLevel
            key={rootKey}
            index={rootPos}
            blockName={ROOT_BLOCK}
            hierarchy={NAV_HIERARCHY?.root}
            data={rootNode}
          >
            {mainCats.length > 0 && (
              <Accordion
                className={MAIN_BLOCK}
                allowZeroExpanded
                onChange={(val) => handleExpand(val, NAV_HIERARCHY?.main)}
              >
                {mainCats.map((mainNode, mainIdx) => {
                  const { id: mainKey, categories: subCats = [] } = mainNode;
                  const mainPos = humanListCount(mainIdx);
                  return (
                    <NestLevel
                      key={mainKey}
                      index={`${rootPos}-${mainPos}`}
                      blockName={MAIN_BLOCK}
                      hierarchy={NAV_HIERARCHY?.main}
                      data={mainNode}
                    >
                      {subCats.length > 0 &&
                        subCats.map((subNode, subIdx) => {
                          const subPos = humanListCount(subIdx);
                          return (
                            <NestLevel
                              key={subNode.id}
                              index={`${rootPos}-${mainPos}-${subPos}`}
                              blockName={SUB_BLOCK}
                              hierarchy={NAV_HIERARCHY?.sub}
                              data={subNode}
                              final
                            />
                          );
                        })}
                    </NestLevel>
                  );
                })}
              </Accordion>
            )}
          </NestLevel>
        );
      })}
    </Accordion>
  );
}
